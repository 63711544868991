import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import UnderlineImage from "./underlineImage"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const Section = styled.section`
  display: grid;
  grid-template-columns: ${props => props.alignRight ? "1fr min-content" : "min-content 1fr"};
  grid-template-rows: 1fr;
  grid-column-gap: 11.5rem;
  grid-column: center-start / center-end;

  .underline-image {
    ${props => props.alignRight ? "grid-column: 2 / 2;" : ""};
  }

  @media only screen and (${mediaQueries.tabPort}) {
    grid-column-gap: 5rem;
  }

  @media only screen and (${mediaQueries.phonePort}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    grid-row-gap: 5rem;

    .underline-image {
      grid-column: 1 / 1;
      grid-row: 2 / 2;
    }
  }

  .underline-image-img {
    width: 44.2rem;
    height: 48.6rem;
    border-radius: 5px;

    @media only screen and (${mediaQueries.tabPort}) {
      width: 30rem;
      height: 34rem;
    }

    @media only screen and (${mediaQueries.phonePort}) {
      width: auto;
      height: auto;
    }
  }
`

const StepDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15%;
  ${props => props.alignRight ? "grid-column: 1 / 1" : ""};
  ${props => props.alignRight ? "grid-row: 1 / 1" : ""};

  @media only screen and (${mediaQueries.tabPort}) {
    padding-bottom: 0;
  }
  @media only screen and (${mediaQueries.phonePort}) {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
`

const StepNumber = styled.span`
  font-family: ${props => props.theme.fonts.special};
  font-size: 10rem;
  font-weight: 700;
  color: ${props => props.theme.color.primary};
  opacity: 0.1;
  display: block;
  margin-bottom: -4rem;
  margin-left: -0.7rem;

  @media only screen and (${mediaQueries.phoneLand}) {
    font-size: 8rem;
    margin-left: -0.3rem;
    margin-bottom: -2.5rem;
  }

  @media only screen and (${mediaQueries.phonePort}) {
    margin: 0 2rem 0 0;
  }
`

const StepTitleContainer = styled.div`
  @media only screen and (${mediaQueries.phonePort}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: blue;
  }
`

const StepTitle = styled.h2`
  font-size: 4.4rem;
  font-family: ${props => props.theme.fonts.text};
  font-weight: ${props => props.theme.weight.regular};
  position: relative;
  margin-bottom: 4rem;

  &:after {
    content: "";
    width: 25%;
    height: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.color.primary};
  }

  @media only screen and (${mediaQueries.phoneLand}) {
    margin-bottom: 2rem;
  }

  @media only screen and (${mediaQueries.phonePort}) {
    font-size: 3.7rem;
    width: 80%;
  }
`

const Text = styled.p`
  font-size: 1.6rem;
`

const CardStep = ({ id, image, title, subTitle, imageTitle, imageAlt, text, stepNumber }) => {

  const images = useStaticQuery(graphql`
      query {
          projects: allFile(filter: {relativeDirectory: {eq: "process"}}) {
              edges {
                  node {
                      childImageSharp {
                          fluid(quality: 80) {
                              ...GatsbyImageSharpFluid_withWebp,
                              originalName
                          }
                      }
                  }
              }
          }
      }
  `)

  const stepImage = images.projects.edges
    .find(project => project.node.childImageSharp.fluid.originalName === image)
  const alignRight = stepNumber % 2 === 0

  return (
    <Section id={id} alignRight={alignRight}>
      <UnderlineImage>
        <Img fluid={stepImage.node.childImageSharp.fluid}
             alt={imageAlt}
             title={imageTitle}
             objectFit={"cover"}
             className="underline-image-img"
             loading={"lazy"} />
      </UnderlineImage>
      <StepDetails alignRight={alignRight}>
        <StepTitleContainer>
          <StepNumber>{stepNumber}</StepNumber>
          <StepTitle>{title}</StepTitle>
        </StepTitleContainer>
        <Text>{text}</Text>
      </StepDetails>
    </Section>
  )
}

export default CardStep