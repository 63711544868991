import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import CardStep from "../components/cardStep"
import HeroTemplate from "../components/heroTemplate"
import TopScroll from "../components/button/TopScroll"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const LayoutStyled = styled(Layout)`
  grid-template-rows: repeat(9, min-content);
  grid-row-gap: 15rem;

  @media only screen and (${mediaQueries.tabPort}) {
    grid-row-gap: 10rem;
  }
 
  @media only screen and (${mediaQueries.phonePort}) {
    grid-row-gap: 5rem;
  }
`

const Process = () => {
  const { t } = useTranslation()

  const steps = [...Array(7)].map((e, i) => {
    const stepNumber = i + 1
    return (
      <CardStep key={stepNumber}
                id={"step-" + stepNumber}
                image={"process-step-" + stepNumber + ".jpg"}
                title={t("process.step" + stepNumber + ".title")}
                imageTitle={t("process.step" + stepNumber + ".image.title")}
                imageAlt={t("process.step" + stepNumber + ".image.title")}
                subTitle={t("process.step" + stepNumber + ".subtitle")}
                stepNumber={stepNumber}
                text={t("process.step" + stepNumber + ".text")}/>
    )
  })

  return (
    <LayoutStyled>
      <SEO title={t("seo.process.title")} description={t("seo.process.description")} canonical={"process"}/>
      <TopScroll target={"hero-process-section"}/>
        <HeroTemplate picture="process-header-l.jpg"
                      title={t("process.hero.title")}
                      subTitles={[t("process.hero.text1"), t("process.hero.text2")]}
        />
        {steps}
        <Contact title={t("process.contact.title")}
                 prefixTitle={t("process.contact.prefix.title")}
                 withBackground={true}/>
    </LayoutStyled>
  )
}

export default Process